import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
// import { upLoadFile } from '../../request/request'
import root from '@/minxin/root'
const Index = {
  name: 'goods-warehousing',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin, root],
  mounted() {
    this.QueryInStockList()
    this.SelectType_ = false
  },
  beforeUpdate() {

  },
  data() {
    return {
      test_pop: false,
      allpageLoading: true,
      root_name: 'sprk',
      root_msg: '商品入库',
      visibl1e: false,
      visible: false,
      visible1: false,
      adSearchForm: [],
      // 物料
      adSearchForm1: [
        { name: '预入库单号', value: 'ASNID' },
        { name: '采购订单号', value: 'PurchaseOrderID' },
        { name: '物料编号', value: 'InvCode' },
        { name: '品牌', value: 'BrandName' },
        { name: '物料名称', value: 'InvName' },
        { name: '物料类型', value: 'MaterialTypeName' },
        { name: '物料简称', value: 'InvShotName' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: '注册证号', value: 'CertificateName' },
        { name: 'UDI码', value: 'DINum' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '生产厂家', value: 'OrigFactory' },
        { name: '入库单号', value: 'InStockOrderID' },
        { name: '操作人', value: 'OperationNickName' },
        { name: '时间', value: 'date', date: true }
      ],
      // 订单
      adSearchForm2: [
        { name: '预入库单号', value: 'ASNID' },
        { name: '采购订单号', value: 'PurchaseOrderID' },
        { name: '送达方', value: 'CustomerCompanyName' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: '时间', value: 'date', date: true }
      ],
      formSearch: {
        KeyWords: '', // '关键字',
        ASNID: '', //  '预入库单号',
        PurchaseOrderID: '', //  '采购订单号',
        InvCode: '', //  '物料编号',
        BrandName: '', // '品牌',
        InvName: '', // '物料名称',
        MaterialTypeName: '', // '物料类型',
        InvShotName: '', //  '物料简称',
        SellCompanyName: '', //  '供应商',
        CertificateName: '', //  '注册证号',
        DINum: '', // 'UDI码',
        ManuPartNum: '', // '原厂编号',
        OrigFactory: '', //  '生产厂家',
        BeginDate: '', //  '开始时间',
        EndDate: '', // '结束时间',
        InStockOrderID: '', //  入库单号,
        OperationNickName: '', // 操作人
        CustomerCompanyName: '', // 送达方,
        date: []
      },
      arr: [],
      form: {},
      SelectType: 1,
      SelectType_: false,
      arrForm: [],
      selects: [],
      row: 0,
      i: 0,
      StockList: []//  入库仓库 QueryInStockList
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
      console.log(e)
    },
    select(row) {
      this.selects = row
    },
    // 获取入库仓库地址
    QueryInStockList() {
      this.$api.QueryInStockList().then(res => {
        console.log(res)
        this.StockList = res.Message
      })
    },
    // 组成提交数据
    setFormData(row) {
      const obj = {
        Url: '',
        FileName: '',
        MaterialRefCompanyID: '',
        DINum: '', // DINum码
        IDX: '', // 明细IDX,
        LotNum: '', // 入库批号/序列号,
        ProductDate: '', // 生产日期,
        ExpireDate: '', // 效期,
        InStockNum: '', // 入库数量,
        ExternalPacking: '', // 外包装,
        Temperature: '', // 到货温度,
        StockID: '' // 入库仓库
      }
      for (const key in obj) {
        obj[key] = row[key]
      }
      // if (row.isNoSet) { delete obj.IDX }
      // if (!row.isNoSet) { delete obj.DetailID }
      // if (row.children && row.children.length > 0) {
      //   row.children.forEach(item2 => {
      //     this.setFormData(item2)
      //   })
      // }
      this.arrForm.push(obj)
    },
    click_I(row) {
      // row.test_pop = !row.test_pop
      this.$set(row, 'test_pop', !row.test_pop)
      this.tableData.forEach(item => {
        if (row.IDX !== item.IDX) {
          // item.test_pop = false
          this.$nextTick(() => {
            this.$set(item, 'test_pop', false)
          })
        }
      })
      // row.test_pop = !row.test_pop
      // this.$nextTick(() => {
      //   this.$set(row, 'test_pop', !row.test_pop)
      //   this.tableData.forEach(item => {
      //     if (row.IDX !== item.IDX) {
      //       // item.test_pop = false
      //       this.$nextTick(() => {
      //         this.$set(item, 'test_pop', false)
      //       })
      //     }
      //   })
      // })
    },
    // 判断是否超出
    isAllNums(row) {
      if (row.ReceiveNum * 1 >= row.Num * 1) return false
      if (row.ReceiveNum * 1 + row.InStockNum * 1 >= row.Num * 1) return false
      if (row.children && row.children.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let childrenNum = 0
        row.children.forEach(item => {
          childrenNum += item.InStockNum * 1
        })
        if (childrenNum * 1 + row.ReceiveNum * 1 + row.InStockNum * 1 >= row.Num * 1) return false
        // if (row.Num === row.ReceiveNum) return false
      }
      return true
    },
    // 拆分
    chaifen(index, row) {
      row.test_pop = false
      if (!this.isAllNums(row)) return this.$message.warning('已入数量已经等于总数量')
      const temp = JSON.parse(JSON.stringify(row))
      temp.LotNum = ''
      temp.ProductDate = ''
      temp.ExpireDate = ''
      temp.InStockNum = ''
      temp.ExternalPacking = ''
      temp.Temperature = ''
      temp.DINum = ''
      temp.isNoSet = true
      this.tableData.splice(index + 1, 0, temp)
      this.setspanRow()
    },
    // 删除拆分
    delChaif(index, rows) {
      this.tableData.splice(index, 1)
      this.setspanRow()
    },
    // 处理数据
    setspanRow() {
      this.tableData.forEach(item => {
        item.spanRow = 0
        this.tableData.forEach((ele) => {
          if (item.IDX === ele.IDX) {
            item.spanRow += 1
          }
        })
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      const _arr = [1, 2, 3, 4, 5, 6, 7] // 要合并的列
      if (_arr.indexOf(columnIndex) > -1) {
        if (!row.isNoSet) {
          return [row.spanRow, 1]
        } else {
          return [0, 0]
        }
      }
    },
    // 确认入库
    OrderASNReceiveLogSave() {
      if (this.selects.length <= 0) return this.$message.warning('请选择你要入库的物料')
      const arr1 = this.selects.filter(item => !item.ExpireDate)
      if (arr1.length > 0) return this.$message.warning('有效期不能为空')
      const arr = this.selects.filter(item => !item.InStockNum)
      if (arr.length > 0) return this.$message.warning('数量不能为空')

      const arr2 = this.selects.filter(item => !item.StockID)
      if (arr2.length > 0) return this.$message.warning('仓库不能为空')

      const arr3 = this.selects.filter(item => !item.LotNum)
      if (arr3.length > 0) return this.$message.warning('批号不能为空')
      // const arr4 =  this.selects.filter(item => {
      //    if (i)
      // })  Num
      this.selects.forEach(item => {
        this.setFormData(item)
      })
      this.$api.OrderASNReceiveLogSave({ ParamsList: this.arrForm, FileUrl: this.form.FileUrl }).then(res => {
        console.log(res)
        this.arrForm = []
        if (res.RetCode === '0') {
          this.$message.success('创建成功')
          return this.refreshCurrentChange()
          // 执行业务逻辑
        } else {
          return this.$message.error('错误：' + res.RetMsg)
        }
      })
    },
    // 查看
    viewOrder(row) {
      this.SelectType = 1
      this.SelectType_ = true
      console.log(row.PurchaseOrderID)
      this.formSearch.ASNID = row.ASNID
      this.getTableDataAsync()
      // this.$router.push({
      //   name:'',
      //   params: {type:1}
      // })
    },
    OrderASNReceiveLogSave1(row) {
      console.log(row)
      // this.test_pop = false
      row.test_pop = false
      // const arr1 = this.selects.filter(item => !item.ExpireDate)
      if (!row.ExpireDate) return this.$message.error('有效期不能为空')
      // const arr = this.selects.filter(item => !item.InStockNum)
      if (!row.InStockNum) return this.$message.error('数量不能为空')
      if (!row.StockID) return this.$message.error('仓库不能为空')
      if (!row.LotNum) return this.$message.error('批号不能为空')
      this.setFormData(row)
      this.allpageLoading = true
      this.$api.OrderASNReceiveLogSave({ ParamsList: this.arrForm }).then(res => {
        console.log(res)
        this.arrForm = []
        this.allpageLoading = false
        if (res.RetCode === '0') {
          this.$message.success('创建成功')
          return this.refreshCurrentChange()
          // 执行业务逻辑
        } else {
          return this.$message.error('错误：' + res.RetMsg)
        }
      }).catch(() => {
        this.allpageLoading = false
      })
      // eslint-disable-next-line no-unreachable
      return
    },
    choiceImg() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    // 上传附件
    uploadFile(index, row) {
      this.row = index
      this.$refs.filElem1.dispatchEvent(new MouseEvent('click'))
    },
    getFile1() {
      const _this = this
      const inputFile = this.$refs.filElem1.files[0]
      if (inputFile) {
        if (inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif') {
          alert('不是有效的图片文件！')
          return
        }
        const imgInfo = Object.assign({}, this.imgInfo, {
          name: inputFile.name,
          size: inputFile.size,
          lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
        })
        if (imgInfo.size >= 5 * 1024 * 1024) return this.$message.error('文件不能超过5M')
        const reader = new FileReader()
        reader.readAsDataURL(inputFile)
        reader.onload = function(e) {
          imgInfo.Url = e.target.result
          const notify = _this.$notify
          notify.info({
            title: '文件正在上传中...',
            dangerouslyUseHTMLString: true,
            message: `<span>文件正在上传中</span>`,
            duration: 0
          })
          const obj = document.getElementsByClassName('el-notification__content')[0]
          const load = _this.$loading({
            target: obj,
            text: ''
          })
          _this.$api.OtherInStockUploadAccessory({
            FileExtName: imgInfo.name,
            Base64String: imgInfo.Url
          }).then(res => {
            console.log(res)
            notify.closeAll()
            load.close()
            if (res.RetCode === '0') {
              _this.$notify.success({
                title: '消息',
                message: `附件上传成功，请确认入库`
              })
              _this.tableData[_this.row].Url = res.Message
              _this.tableData[_this.row].FileName = imgInfo.name
            } else {
              // _this.$message.error('上传失败')
              _this.$notify.error({
                title: '消息',
                message: `附件上传失败，请确认入库`
              })
            }
          })
          _this.$refs.filElem1.value = null
        }
      }
    },
    getFile() {
      const inputFile = this.$refs.filElem.files[0]
      if (inputFile) {
        if (inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif') {
          alert('不是有效的图片文件！')
          return
        }
        const imgInfo = Object.assign({}, this.imgInfo, {
          name: inputFile.name,
          size: inputFile.size,
          lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
        })
        if (imgInfo.size >= 5 * 1024 * 1024) return this.$message.error('文件不能超过5M')
        console.log(imgInfo)
        const reader = new FileReader()
        const _this = this
        // const one = _this.$notify
        // let percentLoaded = 0
        // _this.$notify.info({
        //   title: '文件读取中...',
        //   message: `${percentLoaded}%`
        // })
        // reader.onprogress = function(e) {
        //   console.log(e)
        //   if (e.lengthComputable) {
        //     percentLoaded = Math.round((e.loaded / e.total) * 100)
        //     // 更新进度条长度
        //     if (percentLoaded < 100) {
        //       one.info({
        //         title: '文件读取中...',
        //         message: `${percentLoaded}%`
        //       })
        //     }
        //     one.close()
        //     _this.$notify.info({
        //       title: '文件读取成功，正在上传后台...',
        //       message: `本地文件读取进度 ${percentLoaded}%`
        //     })
        //   }
        // }
        reader.readAsDataURL(inputFile)
        reader.onload = function(e) {
          imgInfo.Url = e.target.result
          console.log('imgInfo', imgInfo)
          _this.setUrl(imgInfo)
          _this.$refs.filElem.value = null
        }
      } else {
        return this.$message.info('12312312')
      }
    },
    // 设置url
    setUrl(imgInfo) {
      const notify = this.$notify
      notify.info({
        title: '文件正在上传中...',
        dangerouslyUseHTMLString: true,
        message: `<span>文件正在上传中</span>`,
        duration: 0
      })
      const obj = document.getElementsByClassName('el-notification__content')[0]
      const load = this.$loading({
        target: obj,
        text: ''
      })
      // upLoadFile({
      //   FileExtName: imgInfo.name,
      //   Base64String: imgInfo.Url
      // }, (res) => {
      //   console.log(res)
      //   const loaded = res.loaded
      //   const total = res.total
      //   this.$nextTick(() => {
      //     this.i = (loaded / total) * 100
      //     console.log(this.i)
      //   })
      // }, (res) => {
      //   notify.closeAll()
      //   load.close()
      //   console.log(res)
      //   if (res.RetCode === '0') {
      //     this.form.FileUrl = ''
      //     this.$notify.success({
      //       title: '消息',
      //       message: `附件上传成功，请确认入库`
      //     })
      //     this.form.FileUrl = res.Message // 大附件
      //   } else {
      //     this.$notify.error({
      //       title: '消息',
      //       message: `附件上传失败`
      //     })
      //   }
      // })
      this.$api.OtherInStockUploadAccessory({
        FileExtName: imgInfo.name,
        Base64String: imgInfo.Url
      }).then(res => {
        notify.closeAll()
        load.close()
        if (res.RetCode === '0') {
          this.form.FileUrl = ''
          this.$notify.success({
            title: '消息',
            message: `附件上传成功，请确认入库`
          })
          this.form.FileUrl = res.Message // 大附件
        } else {
          this.$notify.error({
            title: '消息',
            message: `附件上传失败`
          })
        }
      })
    },
    searchAd() {
      console.log(this.formSearch)
      if (this.formSearch.date.length === 2) {
        this.formSearch.BeginDate = this.formSearch.date[0]
        this.formSearch.EndDate = this.formSearch.date[1]
      }
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      if (this.SelectType === 1) {
        this.getTableDataAsync()
      }
      if (this.SelectType === 2) {
        this.getTableDataAsync1()
      }
      this.visibl1e = false
    },
    Adsearch() {
      this.visibl1e = true
      this.adSearchForm = []
      if (this.SelectType === 1) {
        this.adSearchForm = this.adSearchForm1
      }
      if (this.SelectType === 2) {
        this.adSearchForm = this.adSearchForm2
      }
    },
    selectType(type) {
      this.visible = false
      this.currentPage = 1
      if (this.SelectType === type) return
      this.SelectType = type
      this.allpageLoading = true
      if (type === 1) {
        this.SelectType_ = false
        this.formSearch.ASNID = ''
        this.getTableDataAsync()
      }
      if (type === 2) {
        this.getTableDataAsync1()
      }
      this.$nextTick(() => { // 在数据加载完，重新渲染表格
        this.$refs.testTable.doLayout()
      })
    },
    // 按物料
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.QueryOrderASNInfoDetailList(this.formSearch)
      if (response.RetCode !== '0') return this.$message.error('错误：' + response.RetMsg)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.tableData.map(item => {
        this.$set(item, 'spanRow', 1)
        this.$set(item, 'test_pop', false)
      })
      this.allpageLoading = false
      console.log(response)
      return response
    },
    async getTableDataAsync1() {
      this.formSearch.PageIndex = 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.QueryOrderASNInfoListWithOrder(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.allpageLoading = false
      console.log(response)
      return response
    },
    exportS() {
      if (this.SelectType !== 1) return this.$message.warning('只有物料可以导出')
      this.visible1 = !this.visible1
    },
    // 导出
    ExportQueryOrderASNInfoDetail(type) {
      console.log(type)
      this.visible1 = false
      this.formSearch.ExportType = type ? 0 : 1
      if (type) {
        // 全部
        this.$api.ExportQueryOrderASNInfoDetail(this.formSearch).then(res => {
          console.log(res)
          if (res.RetCode === '0' && res.Message) {
            return this.$minCommon.downloadFile(res.Message, '.xls')
          }
          return this.$message.error('请求失败')
        })
      }
      if (!type) {
        if (this.selects.length <= 0) return this.$message.warning('请选择你要导出的物料')
        const arr = []
        this.selects.forEach(item => {
          arr.push(item.IDX)
        })
        this.formSearch.SelectIDList = arr
        // 批量 SelectIDList
        this.$api.ExportQueryOrderASNInfoDetail(this.formSearch).then(res => {
          console.log(res)
          if (res.RetCode === '0') {
            this.$minCommon.downloadFile(res.Message, '.xls')
          }
        })
      }
      //  物料导出
      // this.$api.ExportQueryOrderASNInfoDetail(this.formSearch).then(res => {
      //   console.log(res)
      //   if (res.RetCode === '0') {
      //     this.$minCommon.downloadFile(res.Message, '.xls')
      //   }
      // })
    }
  }
}
export default Index
