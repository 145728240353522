<template>
  <div class="goods-warehousing">
    <div>
      <PageHeader title="商品入库">
      <!-- <el-progress type="circle" :percentage="i" /> -->
      </PageHeader>
      <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
        <div class="flex">
          <!--   v-model="visible" -->
          <el-popover
            v-if="$minCommon.checkMeau('sprkview', userBottons)"
            class="OrderDropDown"
            placement="bottom"
            trigger="hover"
            :width="80"
            popper-class="test_pop_view"
          >
            <div class="flex column">
              <div class="cursor" @click="selectType(1)">按物料查询</div>
              <div class="p-tb-10 cursor" @click="selectType(2)">按订单查询</div>
            </div>
            <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 m-right-10 pointer" @click="visible = !visible">
              <div>{{ SelectType === 2 ? '按订单查询' : '按物料查询' }}</div>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
          <el-popover
            v-if="$minCommon.checkMeau('sprkexport', userBottons)"
            v-model="visible1"
            class="OrderDropDown"
            placement="bottom"
            trigger="manual"
            :width="80"
            popper-class="test_pop_view1"
          >
            <div class="flex column">
              <div class="p-bottom-10 cursor" @click="ExportQueryOrderASNInfoDetail(1)">全部导出</div>
              <div class="cursor" @click="ExportQueryOrderASNInfoDetail(0)">按已选导出</div>
            </div>
            <!-- <div
              slot="reference"
              class="out p-lr-10 min-border flex a-center m-right-10 pointer ModelBttton-white-30"
              @click="exportS "
            >
              <i class="el-icon-upload2 f15 p-right-10" /> 导出
            </div> -->
          <!-- <div
            slot="reference"
            class="out   p-lr-10 p-tb-5 min-border flex a-center f12 m-right-10 pointer"
            @click="ExportQueryOrderASNInfoDetail"
          >
            <i class="el-icon-upload2 f15 p-right-10" /> 导出
          </div> -->
          </el-popover>

          <!-- <div v-if="$minCommon.checkMeau('sprkview', userBottons)" class="out p-lr-10 p-tb-5 min-border flex a-center ModelBttton-white-30">
            <i class="el-icon-setting f15 p-right-5" />   字段管理
          </div> -->
        </div>
        <div v-if="$minCommon.checkMeau('sprkview', userBottons)" class="flex m-left-10 SearchBox_30" style="flex: 1">
          <el-input
            v-model="formSearch.KeyWords"
            size="medium"
            :placeholder="SelectType ===1 ? '可通过预入库单号、订单号、物料号、物料名称、品牌、物料简称、供应商名称、物料di码、物料注册证搜索':'可通过预入库单号、订单号、供应商名称、终端名称搜索'"
            class="rule-input-edit"
          >
            <template slot="prepend">
              <div class="pointer" @click="Adsearch">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template>
            <div
              slot="append"
              class="cursor"
              @click="searchGetTableDataAsync"
            >
              搜 索
            </div>
          </el-input>
        </div>
        <div class="flex caozuo">
          <div
            v-if="!SelectType_ && $minCommon.checkMeau('sprkinstocklog', userBottons)"
            class="out p-lr-10 p-tb-5 min-border flex a-center f12 m-lr-10 pointer cursor ModelBttton-white-30"
            @click="() => $router.push('warehousing-record')"
          >
            <i class="el-icon-document f15 p-right-10" /> 入库记录
          </div>
          <!-- <div
          v-if="!SelectType_"
          class="out   p-lr-10 p-tb-5 min-border flex a-center f12 m-lr-10 pointer cursor"
        >
          <i class="el-icon-download f15 p-right-10" /> 导入
        </div> -->
          <div v-if="$minCommon.checkMeau('sprkuploadattr', userBottons)" class="out input_file  p-lr-10 p-tb-5 min-border flex a-center pointer cursor ModelBttton-white-30" @click="choiceImg">
            <i class="el-icon-paperclip f15" />
            <div>上传附件</div>
            <input ref="filElem" type="file" class="upload-file" @change="getFile">
          </div>
          <div
            v-if="!SelectType_ && $minCommon.checkMeau('sprkotherintosk', userBottons)"
            class="out p-lr-10 p-tb-5 min-border flex a-center m-lr-10 pointer cursor ModelBttton-white-30"
            @click="() => $router.push('other-warehousing')"
          >
            <i class="el-icon-circle-plus-outline f15 p-right-10" /> 其他入库
          </div>

          <div v-if="$minCommon.checkMeau('sprkconfirminstock', userBottons)" slot="reference" class=" cursor out white bg min-border-right flex a-center f12 pointer ModelBttton-blue-30" @click="OrderASNReceiveLogSave">
            <div>确认入库</div>
          </div>
        </div>
      </div>
      <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'date','BeginDate','EndDate')" />
      <div v-loading="allpageLoading" class="p-lr-20">
        <el-table
          v-if="SelectType === 1"
          ref="testTable"
          class="TableModel18"
          :data="tableData"
          style="width: 100%;"
          :header-cell-style="headerCStyle"
          :span-method="arraySpanMethod"
          row-key="index"
          border
          height="calc(100vh - 330px)"
          default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          @select-all="selectAll"
          @select="select"
        >
          <el-table-column fixed="left" type="selection" width="40" />
          <el-table-column v-if="!SelectType_" align="center" prop="ASNID" label="预入库单号" show-overflow-tooltip />
          <el-table-column align="center" prop="InvName" label="物料名称" show-overflow-tooltip />
          <el-table-column align="center" prop="BrandName" label="品牌" show-overflow-tooltip />
          <el-table-column align="center" prop="MaterialTypeShow" label="物料类型" show-overflow-tooltip />
          <el-table-column align="center" prop="Specification" label="规格" show-overflow-tooltip />
          <el-table-column align="center" prop="StockUnitShow" label="单位" show-overflow-tooltip />
          <el-table-column align="center" prop="" label="已入/订购数量">
            <template slot-scope="scope">
              {{ scope.row.ReceiveNum }} / {{ scope.row.Num }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="LotNum" label="批号/序列号">
            <template slot-scope="scope">
              <el-input v-model="scope.row.LotNum" size="mini" />
            </template>
          </el-table-column>

          <el-table-column align="center" prop="ProductDate" label="生产日期" width="155px">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.ProductDate"
                type="date"
                placeholder="选择日期"
                size="mini"
                style="width:130px"
                value-format="yyyy-MM-dd"
              />
              <!-- <el-input v-model="scope.row.ProductDate" size="mini" /> -->
            </template>
          </el-table-column>

          <el-table-column align="center" prop="ExpireDate" label="效期" width="155px">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.ExpireDate"
                type="date"
                placeholder="选择日期"
                size="mini"
                style="width:130px"
                value-format="yyyy-MM-dd"
              />
              <!-- <el-input v-model="scope.row.ExpireDate" size="mini" /> -->
            </template>
          </el-table-column>

          <el-table-column align="center" prop="InStockNum" label="入库数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.InStockNum" size="mini" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="StockID" label="仓库" width="130px">
            <template slot-scope="scope">
              <!-- <el-input v-model="scope.row.Location" size="mini" /> -->
              <!-- //StockList -->
              <el-select v-model="scope.row.StockID" style="width:100px" size="mini" placeholder="请选择">
                <el-option
                  v-for="(item,index) in StockList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
          </el-table-column>
          <!-- <af-table-column v-show="!SelectType_" prop="ExternalPacking" label="外包装" /> -->
          <el-table-column align="center" prop="ExternalPacking" label="外包装" width="130px">
            <template slot-scope="scope">
              <div>
                <el-select v-model="scope.row.ExternalPacking" size="mini" style="width:100px" placeholder="请选择">
                  <el-option
                    v-for="item in [{label:'完整',value:1},{label:'破损', value: 0}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <!-- <div v-show="!SelectType_">
                {{ scope.row.ExternalPacking ? '完好':'破损' }}
              </div> -->
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Temperature" label="到货温度" width="130px">
            <template slot-scope="scope">
              <div>
                <el-select v-model="scope.row.Temperature" size="mini" style="width:100px" placeholder="请选择">
                  <el-option
                    v-for="item in [{label:'合格',value:1},{label:'不合格', value: 0}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <!-- <div v-show="!SelectType_">
                {{ scope.row.Temperature ? '合格':'不合格' }}
              </div> -->
            </template>
          </el-table-column>

          <el-table-column align="center" prop="DINum" label="DI码">
            <template slot-scope="scope">
              <el-input v-model="scope.row.DINum" size="mini" />
            </template>
          </el-table-column>

          <el-table-column align="center" prop="InStockState" label="入库状态" show-overflow-tooltip />
          <el-table-column align="center" prop="InvCode" label="物料编号" show-overflow-tooltip />
          <el-table-column align="center" prop="PurchaseOrderID" label="采购单号" show-overflow-tooltip />
          <el-table-column align="center" prop="WriteTime" label="预入库单号生成时间" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <el-table-column align="center" prop="ExpressNo" label="物流编号" />
          <el-table-column align="center" prop="UpdaterNickName" label="入库操作人" show-overflow-tooltip />

          <el-table-column align="center" prop="SellCompanyName" label="供应商" show-overflow-tooltip />
          <el-table-column
            prop="address"
            label="操作"
            fixed="right"
            align="center"
            width="50"
          >
            <template slot-scope="scope">

              <!--v-model="scope.row.test_pop"  -->
              <el-popover
                placement="left"
                trigger="hover"
                :width="100"
              >
                <div class="DropDownList">
                  <div v-if="$minCommon.checkMeau('sprkconfirminstock', userBottons)" class="OperationButton" @click="OrderASNReceiveLogSave1(scope.row)">
                    <el-link :underline="false"><i class="el-icon-view p-right-10" />确认入库</el-link>
                  </div>
                  <div v-if="$minCommon.checkMeau('sprkeidtuploadattr', userBottons)" class="OperationButton" @click="uploadFile(scope.$index, scope.row)">
                    <span class="input_file">
                      <el-link :underline="false"><i class="el-icon-paperclip p-right-10" />上传附件</el-link>
                      <input ref="filElem1" style="display:none" type="file" class="upload-file" @change="getFile1">
                    </span>
                  </div>
                  <div v-if="$minCommon.checkMeau('sprksplit', userBottons)" class="OperationButton" @click.stop="chaifen(scope.$index, scope.row)">
                    <el-link :underline="false"><i class="el-icon-connection p-right-10" />拆分</el-link>
                  </div>
                  <div v-if="scope.row.isNoSet && $minCommon.checkMeau('sprkdeletesplit', userBottons)" class="OperationButton" @click.stop="delChaif(scope.$index, scope.row)">
                    <el-link :underline="false"><i class="el-icon-connection p-right-10" />删除拆分</el-link>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more cousor" @click.stop="click_I(scope.row )" />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-if="SelectType === 2 "
          ref="testTable"
          :data="tableData"
          style="width: 100%;"
          :header-cell-style="headerCStyle"
          row-key="index"
          border
          height="calc(100vh - 330px)"
          default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          @select-all="selectAll"
          @select="select"
        >
          <el-table-column align="center" fixed="left" type="selection" width="40" />
          <el-table-column align="center" prop="ASNID" label="预入库单号" />
          <el-table-column align="center" prop="DeliveryTime" label="交货单创建时间" />
          <el-table-column align="center" prop="CustomerCompanyName" label="送达方" />
          <el-table-column align="center" prop="StatusShow" label="入库状态" />
          <el-table-column align="center" prop="PurchaseOrderID" label="采购单号" />
          <el-table-column align="center" prop="WriteTime" label="预入库单号生成时间">
            <template slot-scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ExpressNo" label="物流编号" />
          <el-table-column align="center" prop="SellCompanyName" label="供应商" />
          <el-table-column
            prop="address"
            label="操作"
            fixed="right"
            align="center"
            width="50px"
          >
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="hover"
                popper-class="test_pop_view3"
              >
                <div>
                  <div class="min-border-bottom" @click="viewOrder(scope.row)">
                    <i class="el-icon-view p-right-10" />
                    <span>
                      <el-link type="primary" :underline="false">查看</el-link>
                    </span>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            :ref="paginationName"
            :total="tableTotalSize"
            :pager-count="pagerCount"
            :current-page.sync="currentPage"
            :page-size="tablePageSize"
            :background="tableBackground"
            layout="prev, pager, next, jumper"
            @current-change="handleCurrentChange($event)"
          />
        </div>
      </div>
      <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="visibl1e" @cancel="(e) => visibl1e = e" @searchAd="searchAd" />
    </div>
  </div>
</template>
<script>
import Index from '@/minxin/management/goods-warehousing'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
<style>
.test_pop_view {
  min-width: 60px;
}
.test_pop_view1{
  min-width: 60px;
}
.test_pop_view2{
  min-width: 60px;
}
.test_pop_view3{
  min-width: 60px;
}
</style>
